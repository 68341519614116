<script>
import helperService from "@/services/HelperService";
import CalendarEventsService from "@/services/CalendarEventsService";
import OwnersService from "@/services/OwnersService";
import debounce from "lodash/debounce";
import 'vue-search-select/dist/VueSearchSelect.css';
import { ModelListSelect } from 'vue-search-select'
import moment from "moment";

export default {
  name: "CalendarEventModal",
  components: {ModelListSelect},
  props: {
    event: {
      type: Object,
      default: () => null,
    },
    groups: {
      type: Array,
      default: () => null,
    }
  },
  data() {
    return {
      loading: false,
      calendarEvent: {
        title: '',
        content: '',
        start_date: '',
        end_date: '',
        start_time: '',
        end_time: '',
        model: '',
        model_uid: '',
      },
      debouncedSearch: null,
      ownerFilters: {
        page: 0,
        page_size: 25,
        search: '',
        group_uid: '',
        order_by: 'asc',
        order: 'identifier',
      },
      owners: [],
      hasSearched: false,
    };
  },
  computed: {
    updating() {
      return this.event;
    },
  },
  watch: {
    event: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.calendarEvent = {...newVal};

          // Initialize start_time and end_time from start_date and end_date
          if (newVal.start_date) {
            this.calendarEvent.start_time = moment(newVal.start_date).format('HH:mm');
            this.calendarEvent.start_date = moment(newVal.start_date).format('YYYY-MM-DD')
          }
          if (newVal.end_date) {
            this.calendarEvent.end_time = moment(newVal.end_date).format('HH:mm');
            this.calendarEvent.end_date = moment(newVal.end_date).format('YYYY-MM-DD')
          }

          // When updating, match the model_uid based on uid from groups if model is 'Group'
          if (this.calendarEvent.model === 'Group' && this.groups.length > 0) {
            const matchedGroup = this.groups.find(group => group.uid === newVal.model_uid);
            if (matchedGroup) {
              this.calendarEvent.model_uid = matchedGroup.uid;
            }
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.debouncedSearch = debounce(this.getOwners, 300);
  },
  methods: {
    getValidationState({dirty, validated, valid = null}) {
      return dirty || validated ? valid : null;
    },
    async submitCalendarEvent() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const startDateTime = moment(`${this.calendarEvent.start_date}T${this.calendarEvent.start_time}`);
      const endDateTime = moment(`${this.calendarEvent.end_date}T${this.calendarEvent.end_time}`);

      const dto = {
        title: this.calendarEvent.title,
        content: this.calendarEvent.content,
        start_date: startDateTime.toDate(),
        end_date: endDateTime.toDate(),
        model: this.calendarEvent.model,
        model_uid: this.calendarEvent.model_uid,
      };

      try {
        if (this.updating) {
          await CalendarEventsService.updateCalendarEvent(this.event.model_uid, dto);
        } else {
          await CalendarEventsService.createCalendarEvent(dto);
        }
        let message = `Calendar event successfully ${this.updating ? 'updated' : 'created'}.`
        if (dto.model === 'Owner') {
          message = "Calendar event successfully created. You can view this event in the owner's Calendar."
        }
        helperService.showNotfySuccess(this.$toast, message)
        this.$emit('refresh');
      } catch (err) {
        let msg = `Error ${this.updating ? 'updating' : 'creating'} event`;

        const res = err.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, msg);
      } finally {
        this.loading = false;
      }
    },
    async deleteEvent() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      try {
        await CalendarEventsService.deleteCalendarEvent(this.event.id);
        helperService.showNotfySuccess(this.$toast, `Calendar event successfully deleted.`)
        this.$emit('refresh');
      } catch (err) {
        let msg = 'Error deleting event, please try again.'

        const res = err.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, msg);
      } finally {
        this.loading = false;
      }
    },
    clearModelUid() {
      this.calendarEvent.model_uid = '';
    },
    async getOwners() {
      try {
        const response = await OwnersService.getOwners(this.ownerFilters);
        this.owners = response.data.data;
        this.hasSearched = true;
      } catch (err) {
        let msg = 'Error getting owners';

        const res = err.response;
        if (res && res.data.error) {
          msg = res.data.error
        }

        helperService.showNotfyErr(this.$toast, err, msg);
      }
    },
    handleSearchOwner() {
      if (!this.ownerFilters.search || !this.ownerFilters.search.length) {
        this.owners = [];
        this.hasSearched = false;
        return;
      }
      this.debouncedSearch();
    }
  }
};
</script>

<template>
  <div>
    <section v-if="!loading">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(submitCalendarEvent)">
          <b-form-group>
            <!--Title-->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                Title
              </label>
              <validation-provider
                v-slot="validationContext"
                name="title"
                :rules="{required: true}"
              >
                <b-form-input
                  v-model="calendarEvent.title"
                  placeholder="Enter an event title..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!--Content-->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                Content
              </label>

              <validation-provider
                v-slot="validationContext"
                name="content"
                :rules="{required: true}"
              >
                <b-form-textarea
                  v-model="calendarEvent.content"
                  placeholder="Enter content for the event..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!--Start Date-->
            <validation-provider
              v-slot="validationContext"
              name="start_date"
              :rules="{required: true}"
            >
              <label>Start Date</label>
              <b-datepicker
                v-model="calendarEvent.start_date"
                class="w-100 mb-1"
                :state="getValidationState(validationContext)"
              />
            </validation-provider>

            <!--End Date-->
            <validation-provider
              v-slot="validationContext"
              name="end_date"
              :rules="{required: true, after_or_equal_date: {startDate: calendarEvent.start_date}}"
            >
              <label>End Date</label>
              <b-datepicker
                v-model="calendarEvent.end_date"
                class="w-100 mb-1"
                :state="getValidationState(validationContext)"
              />
            </validation-provider>

            <!--Start Time-->
            <validation-provider
              v-slot="validationContext"
              name="Start time"
              rules="required"
            >
              <label>Start Time</label>
              <b-timepicker
                v-model="calendarEvent.start_time"
                :disabled="!calendarEvent.start_date || !calendarEvent.end_date"
                :state="getValidationState(validationContext)"
                class="w-100 mb-1"
              />
            </validation-provider>


            <!--End Time-->
            <validation-provider
              v-slot="validationContext"
              name="end_time"
              :rules="{
                required: true,
                after_or_equal_time: {
                  startTime: calendarEvent.start_time,
                  startDate: calendarEvent.start_date,
                  endDate: calendarEvent.end_date,
                }
              }"
            >
              <label>End Time</label>
              <b-timepicker
                v-model="calendarEvent.end_time"
                :disabled="!calendarEvent.start_date || !calendarEvent.end_date"
                :state="getValidationState(validationContext)"
                class="w-100 mb-1"
              />
            </validation-provider>

            <!--Event Level Dropdown-->
            <validation-provider
              v-slot="validationContext"
              name="model"
              :rules="{required: true}"
              v-if="!updating"
            >
              <b-input-group class="mb-1 d-flex flex-column mt-1">
                <label>Event Level</label>
                <b-form-select
                  v-model="calendarEvent.model"
                  class="w-100"
                  @change="clearModelUid"
                  :state="getValidationState(validationContext)"
                >
                  <b-form-select-option value="Enterprise">
                    Enterprise
                  </b-form-select-option>
                  <b-form-select-option v-if="$can('Read', 'Group')" value="Group">
                    Group
                  </b-form-select-option>
                </b-form-select>
              </b-input-group>
            </validation-provider>

            <!--Group Dropdown-->
            <validation-provider
              v-if="!updating && calendarEvent.model === 'Group'"
              v-slot="validationContext"
              :disabled="calendarEvent.model !== 'Group'"
              name="model_uid"
              :rules="{required: calendarEvent.model === 'Group'}"
            >
              <b-input-group class="mb-1 d-flex flex-column mt-1">
                <label>Group</label>
                <model-list-select
                  v-model="calendarEvent.model_uid"
                  :list="groups"
                  :state="getValidationState(validationContext)"
                  class="w-100"
                  option-text="name"
                  option-value="uid"
                  placeholder="Select a group"
                />
              </b-input-group>
            </validation-provider>

            <!--Button-->
            <b-input-group class="d-flex flex-row-reverse mt-3">
              <b-btn variant="primary" type="submit" :disabled="invalid">
                {{ updating ? 'Update ' : 'Create ' }}
              </b-btn>
              <b-btn
                v-if="updating && $can('Delete', 'CalendarEvents')"
                variant="danger"
                class="d-inline mr-1"
                :disabled="invalid"
                @click="deleteEvent"
              >
                Delete
              </b-btn>
              <b-btn class="d-inline mr-1" @click="$emit('close')">
                Close
              </b-btn>
            </b-input-group>
          </b-form-group>
        </b-form>
      </validation-observer>
    </section>
  </div>
</template>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>
