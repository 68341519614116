import axios from '@axios';
import HelperService from '@/services/HelperService'

const baseUrl = '/calendar-events';
export default {
  listEvents(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  getOwnerEvents(id, filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}/owner/${id}${queryString}`);
  },
  createCalendarEvent(dto) {
    return axios.post(`${baseUrl}`, dto);
  },
  updateCalendarEvent(id, dto) {
    return axios.put(`${baseUrl}/${id}`, dto);
  },
  deleteCalendarEvent(id) {
    return axios.delete(`${baseUrl}/${id}`);
  }
}
